blockquote {
	border-left: 7px solid #f1f1f1;
	padding-left: 10px;
	margin-bottom: 10px;
	background-color: #f9f7f7;

	p {
		padding: 8px 0;
		color: #7a7a7a;
	}
}

table, ul, ol {
	margin-bottom: 20px;
}

.posts-container {
	margin: 10px 0 35px 0;
	//padding: 10px;
	//background-color: #f4f4f4;
	//border-left: 7px solid #e2dee2;
	//padding: 10px;

	//@include media-query($on-mobile) {
	//	margin: 10px 10px;
	//}
	//padding: 10px;
	//background-color: white;
}

.posts-content-container {
	li {
		margin: 5px 0 5px 30px;
	}

	h1, h2, h3, h4, h5, h6 {
		padding: 7px 0;
	}

	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: '#';
		margin-right: 5px;
		color: $light-red;
	}

	p {
		margin-bottom: 10px;
	}

	// image stuff, center it
    img {
      display: block;
      margin: 0 auto;
      padding: 15px 0;
    }

	iframe , video {
		display: block;
		margin: 15px auto;
		//max-width: $content-width;
		//width: 100%;
		//overflow: hidden;
	}

	// table customization
  table {
    text-align: center;
    margin: 20px auto;
    padding: 0;
    border-spacing: 4px;
    //border-collapse: collapse;

    thead {
        //border: 1px solid #D6D6D6;
	      margin: 10px;
	      padding: 10px;
      tr {
        th {
            //border: 1px solid #D6D6D6;
            padding: 5px 10px;
        }
      }
    }
    tbody {
      tr {
          background-color: #FAFAFA;
        td {
            //border: 1px solid #D6D6D6;
            padding: 8px 12px;
        }
        &:nth-child(even) {
          background-color: #F0F0F0;
        }
      }
    }
  }
}

//@include media-query($on-mobile) {
//	.posts-content-container {
//		margin: 10px;
//	}
//}

.posts-title {
	font-family: $font-mont;
	font-weight: 900;

	a {
		text-decoration: none;

		h2 {
			margin: 0px 0 !important;
		}
	}
}

.posts-title a h4:before {
	content: '#';
	margin-right: 5px;
	color: #c8c8c8;
}

.posts-author {

}

.posts-date {
	p {
		color: #b5b5b5;
	}

	.posts-tag {
		background-color: $light-grey;
		padding: 1px 8px 2px 8px;
		margin-left: 2px;
	}

	> * {
		display: inline-block;
	}
}

.posts-tags {

}

.posts-content {

}

.posts-list {
	list-style: none;
}

.posts-tags p {
	display: inline-block;
}

//
// Archive styling
//

.archive-posts-list {
 	li {
		> * {
			display: inline-block;
		}
	}
}

.archive-post-list-title {

}

.archive-post-list-tags {
	> * {
		display: inline-block;
	}

	> p {
		background-color: $light-grey;
		padding: 1px 8px 2px 8px;
		margin: 2px 0 2px 2px;
	}
}

.archive-post-list-date {

}

//
// Responsive styling
//

@include media-query($on-tablet) {
	.archive-posts-list {
		li {
			margin: 0 10px;
		}
	}
}

@include media-query($on-mobile) {
	.posts-content-container {
		iframe , video {
			max-width: $content-width;
			width: 100%;
			overflow: hidden;
		}
	}
}
