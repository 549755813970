
.project-item {
	margin: 10px 0;
	background-color: $background-color;

	> * {
		padding: 0 10px;
	}

	h4 {
		font-family: $font-open;
		font-size: $font-size + 4pt;
	}

	.project-header {

		.project-link {
			&:hover {
				border: 0 solid #fff !important;
				h4 { color: #a5a5a5; }
			}
		}

		.date {
			float:right;
			margin-top: 5px;

			p {
				background-color: $light-grey;
				padding: 1px 8px 1px 8px;
				margin-left: 2px;

				display: inline-block;
			}
		}
	}

	.project-header	> * {
		display: inline-block;
	}

	.project-meta {
		.desc {
			p {
				color: #a1a1a1;
			}
		}
	}
}

//@include media-query($on-mobile) {
//	.project-item {
//		margin: 7px;
//	}
//}
