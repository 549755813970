@charset "utf-8";

@import "./font-awesome/css/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500|Montserrat|Open+Sans|Roboto|Roboto+Mono|PT+Sans');

$font-robot: 'Roboto', sans-serif;
$font-robot-mono: 'Roboto Mono', monospace;
$font-mont: 'Montserrat', sans-serif;
$font-pt: 'PT Sans', sans-serif;
$font-open: 'Open Sans', sans-serif;
$font-rale: 'Raleway', sans-serif;

// Reset global margin
* {
	margin:  0;
	padding: 0;
}


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


$content-width:      700px;
$on-mobile:          480px; // mobile
$on-tablet:          480px; // tablet

$background-color: #121212;
$font-color:       rgb(216, 211, 201);

$light-grey:     rgb(47, 50, 51);
$light-red:        #a14848;
$brighter-white:   #e6e6e6;
$emph-color:      #d6a058;

$font-size:        14pt;
$font-size-large:  30pt;
$font-size-menu:   15pt;

$spacing-unit: 1.0;


// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"base.scss",
	"posts.scss",
	"project.scss";

@import "gruvbox-dark.scss";