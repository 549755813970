
body {
	margin: 0 auto;
	max-width: $content-width;
	width: 100%;

	background-color: $background-color;
	
}

h1 {
	color: $font-color;
	font-family: $font-rale;
}

h2, h3, h4, h5, h6 {
	color: $brighter-white;
	font-family: $font-open;
}

p, li {
	color: $font-color;
}

strong {
	color: $brighter-white;
}

h1 { font-weight: 700; }
h2, h3, h4, h5, h6 { font-weight: lighter; }

h1 { font-size: $font-size + 23pt; }
h2 { font-size: $font-size + 15pt; }
h3 { font-size: $font-size + 11pt; }
h4 { font-size: $font-size + 8pt; }
h5 { font-size: $font-size + 4pt; }

#intro-container > * {
	display: inline-block;
}

.title-container h1 {
	display: inline-block;
}

.intro-github {
	margin-left: 8px;
}

#title, #dot, #me {
	font-family: $font-rale;
	font-weight: 600;
	display: inline-block;
}

#game {
	width: 1280px;
	height: 720px;
	margin-left: 50%;
	transform: translateX(-50%);
	border: none;
	border-radius: 5px;
}

#me {
	color: #d12414db;
}


a {
	color: #e35353;
	text-decoration: none;

	&:hover {
		border-bottom: 4px solid #e35353;
	}

	&:active {
		color: #913434;
		border-bottom: 4px solid #913434;
	}
}

p, a, ul, ol, li, table, thead, tbody th, tr, td {
	font-family: $font-open;
	font-weight: 400;
	font-size: $font-size;
}

th {
	font-family: $font-open;
	font-weight: 600;
	font-size: $font-size;
}

p, a, ul, ol, li { line-height: 1.5; }

p {
	color: $font-color;
	padding-bottom: 10px;
}

code, pre {
	//font-family: $font-robot-mono;
	//font-weight: 400;
	font-size: $font-size - 2pt;
	// background-color: #efefef;
	margin: 10px 0;
	overflow: auto;
}

code {
	padding: 1px 2px;
}

pre {
	padding: 10px;
}

hr, .seperator, .slim {
	border: 4px solid rgb(47, 50, 51);
	margin: 20px 0;
}

img {
	max-width: $content-width;
	height: auto;
	margin: 0 auto;
	display: block;
}


.slim {
	margin: 7px 0 15px 0;
}

.no-margin {
	margin: 0px;
	padding: 0px;
}

.project-link {
	h4 {
		font-family: $font-open;
		font-weight: 400;
		font-size: $font-size;
	}
}


.header-container { }
.navbar, .title-container { display: inline-block; }
.content-container { clear: both; }
.page-intro { padding: 0 0 10px 0; }
.posts-pagination > * {
	display: inline-block;
}


.navbar {
	//margin: 10px 0;
	margin-top: 10px;
	ul {
		list-style: none;

		li {
			display: inline;
			margin-right: 10px;
		}
		li:not(:last-child):after {
			content: '/';
			margin-left: 15px;
			color: #c8c8c8;
		}
	}
}


.logo {
	max-width: 800px;
	width: 100%;
	margin: 20px 0;
}

.title-container {
	p {
		font-size: $font-size;
		font-weight: 800;
	}
}


.footer-social {

	margin-bottom: 500px;

	.seperator {
		border: 4px solid #f1f1f1 !important;
		margin: 7px 0;
	}

	li {
		list-style: none;
		display: inline-block;

		i { vertical-align: middle; }

		a {
		}
	}
}

//
// Responsive styling
//

@include media-query($on-mobile) {
	h1 { font-size: ($font-size + 23pt) / (1.5 * $spacing-unit); }
	h2 { font-size: ($font-size + 15pt) / (1.5 * $spacing-unit); }
	h3 { font-size: ($font-size + 11pt) / (2 * $spacing-unit); }
	h4 { font-size: ($font-size + 8pt)  / ($spacing-unit); }
	img { max-width: 100%; height: auto; }
}

@include media-query($on-mobile) {
	p, a, ul, ol, li, table, thead, tbody th, tr, td {
		font-family: $font-robot;
		font-weight: 400;
		font-size: $font-size / $spacing-unit;
	}
}

@include media-query($on-mobile) {
	code span, pre > * {
		font-size: 10pt;
	}
}

@include media-query($on-tablet) {
	.content-container {
		margin: 0 10px;
	}
}

@include media-query($on-tablet) {
	.navbar {
		margin: 10px 10px;
	}
}

@include media-query($on-tablet) {
	.footer-social {
		margin: 0 10px 500px 10px;
	}
}
